import React from 'react';
import { RoutePermittedRole } from 'src/shared/constants/AppEnums';
import { routes } from 'src/@crema/config';
import { ROUTER_NAME } from 'src/pages/routeConfig';

const Account = React.lazy(() => import('../HRManagement/Account'));
const UserHistoryLogPage = React.lazy(() =>
  import('../HRManagement/Account/UserHistoryLog'),
);

const Department = React.lazy(() => import('../HRManagement/Department'));
const ResetPassword = React.lazy(() =>
  import('../HRManagement/ResetPassword/index'),
);
const ResetPasswordUser = React.lazy(() =>
  import('../HRManagement/ResetPasswordUser/index'),
);

const LabelManagement = React.lazy(() => import('../LabelManagement'));
const KeywordManagement = React.lazy(() => import('../KeywordManagement'));
const BotManagement = React.lazy(() => import('../BotManagement'));
const ProxyManagement = React.lazy(() => import('../ProxyManagement'));
const SourceManagement = React.lazy(() => import('../SourceManagement'));
const CreateSourceGroupPage = React.lazy(() =>
  import('src/pageComponents/SourceManagement/SourceGroupTable/create'),
);
const EditSourceGroupPage = React.lazy(() =>
  import('src/pageComponents/SourceManagement/SourceGroupTable/edit'),
);

const SentimentManagementPage = React.lazy(() =>
  import('src/pages/SentimentManagement'),
);

export const samplePagesConfigs = [
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/department',
    element: <Department />,
    name: 'department',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/account',
    element: <Account />,
    name: 'account',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: routes.userHistoryLog(':id'),
    element: <UserHistoryLogPage />,
    name: ROUTER_NAME.ACCOUNT,
  },

  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/reset-password',
    element: <ResetPassword />,
    name: 'reset_password',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: routes.resetPasswordUser,
    element: <ResetPasswordUser />,
    name: ROUTER_NAME.RESET_PASSWORD_USER,
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/label',
    element: <LabelManagement />,
    name: 'label',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/keywords',
    element: <KeywordManagement />,
    name: 'keywords',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/source',
    element: <SourceManagement />,
    name: 'source',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: routes.createSourceGroup,
    element: <CreateSourceGroupPage />,
    name: 'source',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: routes.editSourceGroup(':id'),
    element: <EditSourceGroupPage />,
    name: 'source',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/bot',
    element: <BotManagement />,
    name: 'bot',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: '/admin/proxy',
    element: <ProxyManagement />,
    name: 'proxy',
  },
  {
    permittedRole: RoutePermittedRole.ADMIN,
    path: routes.sentimentManagement,
    element: <SentimentManagementPage />,
    name: ROUTER_NAME.SENTIMENT,
  },
];
