import React from 'react';
import clsx from 'clsx';
import { Avatar, Dropdown } from 'antd';
import { FaChevronDown } from 'react-icons/fa';
import './index.style.less';
import { useThemeContext } from '../../../../utility/AppContextProvider/ThemeContextProvider';
import { useAuthMethod, useAuthUser } from '../../../../utility/AuthHooks';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';
import PropTypes from 'prop-types';
import { UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const UserInfo = ({ hasColor }) => {
  const navigate = useNavigate();
  const { themeMode } = useThemeContext();
  const { logout } = useAuthMethod();
  const { user = {} } = useAuthUser();
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();

  const getUserAvatar = () => {
    if (user?.displayName) {
      return user?.displayName.charAt(0).toUpperCase();
    }
    if (user?.email) {
      return user?.email.charAt(0).toUpperCase();
    }
  };

  const items = [
    {
      label: (
        <div onClick={() => navigate('/my-profile')}>Thông tin cá nhân</div>
      ),
      key: 'user_info-2',
    },
    {
      label: <div onClick={() => logout()}>Đăng xuất</div>,
      key: 'user_info-3',
    },
  ];

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx(
            'cr-user-mini-toggle-info cr-user-mini-toggle-info-hasColor',
            {
              light: themeMode === 'light',
            },
          )}>
          <Dropdown
            overlayClassName={'dropdown-list'}
            className='user-profile-dropdown'
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-mini-toggle-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <Avatar
                  className='cr-user-mini-toggle-info-avatar'
                  src={user?.photoURL}
                  size={'default'}
                  icon={<UserOutlined />}
                />
              ) : (
                <Avatar
                  size={'default'}
                  className='cr-user-mini-toggle-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className='cr-user-mini-toggle-info-content'>
                <span className='cr-user-mini-toggle-name-info'>
                  <h3
                    className={clsx('cr-user-mini-toggle-name ', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user?.displayName : 'admin user '}
                  </h3>
                  <span className='cr-user-mini-toggle-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
              </span>
              {user?.photoURL ? (
                <Avatar
                  size={'default'}
                  className='cr-user-mini-toggle-info-avatar'
                  src={user?.photoURL}
                  icon={<UserOutlined />}
                />
              ) : (
                <Avatar
                  size={'default'}
                  className='cr-user-mini-toggle-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx('cr-user-mini-toggle-info', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            overlayClassName={'dropdown-list'}
            className='user-profile-dropdown'
            menu={{ items }}
            trigger={['click']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-mini-toggle-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <Avatar
                  size={'default'}
                  className='cr-user-mini-toggle-info-avatar'
                  src={user?.photoURL}
                  icon={<UserOutlined />}
                />
              ) : (
                <Avatar
                  size={'default'}
                  className='cr-user-mini-toggle-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className='cr-user-mini-toggle-info-content'>
                <span className='cr-user-mini-toggle-name-info'>
                  <h3
                    style={{ whiteSpace: 'nowrap' }}
                    className={clsx('cr-user-mini-toggle-name ', {
                      light: themeMode === 'light',
                    })}>
                    {user?.displayName ? user?.displayName : 'admin user '}
                  </h3>
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
