export const routes = {
  userHistoryLog: (userId) => `/admin/account/historyLog/${userId}`,

  sourceManagementByTab: (tab) => `/admin/source?t=${tab}`,
  createSourceGroup: '/admin/source/create-source-group',
  editSourceGroup: (id) => `/admin/source/edit-source-group/${id}`,

  sentimentManagement: '/admin/sentiment',
  resetPasswordUser: '/admin/reset-password-user',
};
